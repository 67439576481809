import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaView } from 'react-native';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CopyHoursForm from './src/components/ConsultingSessionList/ConsultingSessionCopyForm';
import EditHoursForm from './src/components/ConsultingSessionList/ConsultingSessionEditForm';
import MPAppBar from './src/components/Layout/MPAppBar';
import { AppContextProvider } from './src/contexts/AppContext';
import { useGlobalContext } from './src/contexts/globalContext';
import ConsultingActivities from './src/screens/ConsultingActivities';
import ConsultingPlaces from './src/screens/ConsultingPlaces';
import ConsultingSessionCreate from './src/screens/ConsultingSessionCreate';
import InnerApp from './src/screens/InnerApp';
import Login from './src/screens/Login';
import AuthService from './src/services/AuthService';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import SCREEN from './src/utils/constants/SCREEN';
import destroyIOSStupidScroll from './src/utils/destroyIOSStupidScroll';
import H from './src/utils/helper';
import { navigationRef, resetToScreen } from './src/utils/navigation';


const Stack = createNativeStackNavigator();

const theme = createTheme({
  palette: {
    green: '#007D4A',
    white: '#fff',
    red: '#d32f2f',
    black: '#000'
  },
});

export default function App() {

  const screenOptions = {
    title: '',
    headerBackImageSource: null,
    headerShown: false,
  };

  const { setCredentials } = useGlobalContext();

  const checkStoredCredentials = async () => {
    try {
      const Auth = new AuthService();
      const credentials = await Auth.getCredentials();
      if (credentials) {
        const now = Date.now();
        // Check if credentials are less than 30 days old
        if (now - credentials.timestamp < (30 * 24 * 60 * 60 * 1000)) {
          const Auth = new AuthService(
            H.getEncodedCredentials(credentials.email, credentials.password)
          );
          const authData = await Auth.login(credentials.email, credentials.password);
          if (authData.isAuthorized) {
            await persistUserSession(authData);
            resetToScreen(SCREEN.INNER_APP);
          }
        }
      }
    } catch (error) {
      console.error('Error checking stored credentials:', error);
    }
  };

  const persistUserSession = async (authData) => {
    const credentials = {
      uid: authData.uid,
      email: authData.email,
      password: authData.password,
      timestamp: Date.now(),
    };

    // Save to context
    setCredentials(credentials);

    // Save to localStorage via AuthService
    try {
      const Auth = new AuthService();
      await Auth.saveCredentials(credentials);
    } catch (error) {
      console.error('Error saving credentials:', error);
    }
  };


  return (
    <AppContextProvider>
      {/* children component can read AppContext */}
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          checkStoredCredentials();
        }}
      >
        {/* children component can use react-navigation */}
        <SafeAreaView style={{ flex: 1, backgroundColor: '#E5E5E5FF' }}>
          <ThemeProvider theme={theme}>
            <Stack.Navigator initialRouteName={SCREEN.LOGIN}>
              {/* children components are screen components, stacked navigation */}
              <Stack.Screen name={SCREEN.LOGIN} component={Login} options={screenOptions} />
              <Stack.Screen name={SCREEN.INNER_APP} component={InnerApp} options={screenOptions} />
              <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen
                  name={SCREEN.ADD_HOURS}
                  component={ConsultingSessionCreate}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} />,
                    title: 'Beratungsstunden hinzufügen',
                  })}
                />
                <Stack.Screen
                  name={SCREEN.CONSULTING_ACTIVITIES}
                  component={ConsultingActivities}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} />,
                    title: 'Beratungstätigkeiten',
                  })}
                />
                <Stack.Screen
                  name={SCREEN.CONSULTING_PLACES}
                  component={ConsultingPlaces}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} />,
                    title: 'Veranstaltungsorte',
                  })}
                />
                <Stack.Screen
                  name={SCREEN.EDIT_CONSULTING_SESSION}
                  component={EditHoursForm}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} />,
                    title: 'Beratungsstunden ändern',
                  })}
                />
                <Stack.Screen
                  name={SCREEN.COPY_CONSULTING_SESSION}
                  component={CopyHoursForm}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} />,
                    title: 'Beratungsstunden duplizieren',
                  })}
                />
              </Stack.Group>
            </Stack.Navigator>
          </ThemeProvider>
        </SafeAreaView>
      </NavigationContainer>
    </AppContextProvider>
  );
}

/**
 * We execute some initialization code before the app is ready
 */
((window) => {
  // iOS support
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (isIOS) {
    destroyIOSStupidScroll(window);
  }
})(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
