import CategoryIcon from '@mui/icons-material/Category';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import SecurityIcon from '@mui/icons-material/Security';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Snackbar, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import PasswordChecklist from 'react-password-checklist';
import { useClickAway } from 'react-use';
import OurAlert from '../components/Common/OurAlert';
import OurSlideTransition from '../components/Common/OurSlideTransition';
import { useGlobalContext } from '../contexts/globalContext';
import { useResource } from '../hooks/useResource';
import UserService from '../services/UserService';
import UserSettingService from '../services/UserSettingService';
import SCREEN from '../utils/constants/SCREEN';

export default function Profile({ navigation }) {
  const theme = useTheme();
  const style = StyleSheet.create({
    main: {
      maxWidth: 600,
      alignSelf: 'center',
      paddingVertical: 25,
      paddingHorizontal: 15,
      width: '100%',
    },
    sectionCard: {
      marginBottom: 20,
      width: '100%',
    },
    sectionHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 16,
    },
    sectionTitle: {
      fontSize: 20,
      fontWeight: '500',
      color: '#28465A',
      marginLeft: 8,
    },
    sectionIcon: {
      color: '#28465A',
    },
    formSection: {
      marginBottom: 24,
    },
    wrapper: {
      borderBottomColor: '#E4E9EC',
      borderBottomWidth: 2,
    },
    mainHeader: {
      color: '#28465A',
      fontSize: 22,
      lineHeight: 22,
    },
    username: {
      color: '#28465A',
      fontSize: 22,
    },
    header: {
      color: '#28465A',
      fontSize: 18,
      lineHeight: 18,
      paddingTop: 20,
      paddingBottom: 20,
    },
    text: {
      marginBottom: 20,
    },
    input: {
      marginBottom: 24,
    },
    nameBanner: {
      textAlign: 'center',
      lineHeight: 22,
      fontSize: 22,
      paddingBottom: 30,
      borderBottomColor: '#E4E9EC',
      borderBottomWidth: 2,
    },
    userData: {
      color: '#28465A',
      fontSize: 18,
      lineHeight: 18,
      paddingTop: 30,
      paddingBottom: 20,
    },
    dataInput: {
      width: 345,
      alignSelf: 'center',
      marginBottom: 15,
    },
    blueButtons: {
      width: '100%',
      height: 50,
      // @ts-ignore
      color: theme.palette.white,
      // @ts-ignore
      backgroundColor: theme.palette.green,
      textTransform: 'none',
      marginBottom: 16,
    },
    redButtons: {
      width: '100%',
      height: 50,
      alignSelf: 'center',
      // @ts-ignore
      color: theme.palette.white,
      // @ts-ignore
      backgroundColor: theme.palette.red,
      textTransform: 'capitalize',
    },
    logout: {
      marginVertical: 10,
      // alignSelf: 'baseline',
    },
    downloadDisplay: {
      display: 'flex',
      position: 'absolute',
      bottom: 1,
      width: '95%',
      transform: 'translateX(1%)',
      height: 69,
      backgroundColor: '#323231',
      padding: 5,
      borderRadius: 3,
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontSize: 13,
    },
    hide: {
      display: 'none',
    },
    infoIcon: {
      fontSize: 20,
      color: '#666',
      marginLeft: 8,
    },
    tooltipText: {
      fontSize: 15,
      padding: 4,
      lineHeight: 1.2,
    },
    helperText: {
      marginBottom: 16,
      color: '#666',
      fontSize: 15,
    },
    sectionHeaderWithTooltip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
    },
  });
  const { auth, setCredentials, setActiveTab } = useGlobalContext();
  const [showSnackbarChangeData, setShowSnackbarChangeData] = useState(false);
  const [showSnackbarError, setShowSnackbarError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [formdata, setFormdata] = useState({
    email: auth.email,
    password: auth.password,
  });

  const { data: activities } = useResource({
    name: 'activities',
    defaultData: [],
    cacheName: 'api-activities-cache',
  });

  const { data: cultures } = useResource({
    name: 'cultures',
    defaultData: [],
    cacheName: 'api-cultures-cache',
  });

  const categoryNames = [...new Set(activities.map(a => a.categoryName))];

  const [favoriteSettings, setFavoriteSettings] = useState(() =>
    UserSettingService.getSettings()
  );

  useEffect(() => {
    UserSettingService.saveSettings(favoriteSettings);
  }, [favoriteSettings]);

  const handleCategoryChange = (categoryName) => {
    setFavoriteSettings(prev => ({
      ...prev,
      categories: prev.categories.includes(categoryName)
        ? prev.categories.filter(c => c !== categoryName)
        : [...prev.categories, categoryName]
    }));
  };

  const handleCultureChange = (cultureUid) => {
    setFavoriteSettings(prev => ({
      ...prev,
      selectedCulture: cultureUid
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickInput = () => {
    setShowChecklist(true);
  };

  const handleClickAway = (event) => {
    const target = event.target;
    const isIconButton =
      target.id === 'toggle-password-visibility' ||
      target.closest('#toggle-password-visibility') ||
      target.id === 'btn_change_data' ||
      target.closest('#btn_change_data');
    if (!isIconButton) {
      setShowChecklist(false);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, handleClickAway);

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setActiveTab(0);
    navigation.navigate(SCREEN.LOGIN, {});
  };

  const handleClickChangeData = async () => {
    setShowSnackbarChangeData(true);
    if (!navigator.onLine) {
      setShowSnackbarChangeData(false);
      return;
    }
    const SERVICE = new UserService();
    const updateData = {
      uid: auth.uid,
    };
    let emailValid = false;
    let passwordValid = false;

    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (formdata?.email && formdata.email.match(emailRegex)) {
      updateData.email = formdata.email;
      emailValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }

    // eslint-disable-next-line no-useless-escape
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])[A-Za-z\d@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{5,}$/;
    if (formdata?.password && formdata.password.match(passwordRegex)) {
      updateData.password = formdata.password;
      passwordValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }
    if (emailValid && passwordValid) {
      // @ts-ignore
      await SERVICE.update(updateData);
      setTimeout(() => setShowSnackbarChangeData(false), 3000);
      setCredentials({ ...auth, password: formdata?.password })
    }
  };

  const handleFormChange = (event) => {
    setFormdata((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={style.main}>
        <Card style={style.sectionCard}>
          <CardContent>
            <View style={style.sectionHeader}>
              <PersonIcon style={style.sectionIcon} />
              <Text style={style.sectionTitle}>Profil Information</Text>
            </View>
            <Text style={{ marginBottom: 16, color: '#666' }}>
              UID: {auth.uid}
            </Text>
          </CardContent>
        </Card>

        <Card style={style.sectionCard}>
          <CardContent>
            <View style={style.sectionHeader}>
              <SecurityIcon style={style.sectionIcon} />
              <Text style={style.sectionTitle}>Sicherheit</Text>
            </View>

            <View style={style.formSection}>
              <TextField
                name="email"
                label="E-Mail"
                fullWidth
                value={formdata.email}
                onChange={handleFormChange}
                variant="outlined"
              />
            </View>

            <View style={style.formSection}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Neues Passwort</InputLabel>
                <OutlinedInput
                  value={formdata.password}
                  name="password"
                  onChange={handleFormChange}
                  onClick={handleClickInput}
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        id="toggle-password-visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Neues Passwort"
                  inputRef={ref}
                />
                {showChecklist && (
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital']}
                    minLength={6}
                    value={formdata.password}
                    // @ts-ignore
                    onChange={(isValid) => { }}
                    messages={{
                      // @ts-ignore
                      minLength: <Text>Das Passwort enthält mehr als 5 Zeichen</Text>,
                      // @ts-ignore
                      specialChar: <Text>Das Passwort enthält Sonderzeichen</Text>,
                      // @ts-ignore
                      number: <Text>Das Passwort enthält Zahlen</Text>,
                      // @ts-ignore
                      capital: <Text>Das Passwort enthält Großbuchstaben</Text>,
                    }}
                    style={{ padding: 10 }}
                  />
                )}
              </FormControl>
            </View>
          </CardContent>
        </Card>

        <Card style={style.sectionCard}>
          <CardContent>
            <View style={style.sectionHeaderWithTooltip}>
              <CategoryIcon style={style.sectionIcon} />
              <Text style={style.sectionTitle}>Tätigkeitskategorien</Text>
              <Tooltip title={<span style={style.tooltipText}>Wählen Sie Ihre häufigsten Tätigkeitsbereiche aus, um schneller darauf zugreifen zu können</span>}
              >
                <InfoIcon style={style.infoIcon} />
              </Tooltip>
            </View>
            <FormHelperText style={style.helperText}>
              Diese Auswahl bestimmt, welche Tätigkeiten Ihnen angezeigt werden. Sie können dadurch schneller auf Ihre häufigsten Tätigkeiten zugreifen.
            </FormHelperText>

            <FormGroup>
              {categoryNames.map((categoryName) => (
                <FormControlLabel
                  key={categoryName}
                  control={
                    <Checkbox
                      checked={favoriteSettings.categories.includes(categoryName)}
                      onChange={() => handleCategoryChange(categoryName)}
                    />
                  }
                  label={categoryName}
                />
              ))}
            </FormGroup>
          </CardContent>
        </Card>

        <Card style={style.sectionCard}>
          <CardContent>
            <View style={style.sectionHeaderWithTooltip}>
              <LocalFloristIcon style={style.sectionIcon} />
              <Text style={style.sectionTitle}>Bevorzugte Kultur</Text>
              <Tooltip title={<span style={style.tooltipText}>Wählen Sie Ihre Hauptkultur aus, um Zeit bei der Eingabe zu sparen</span>}>
                <InfoIcon style={style.infoIcon} />
              </Tooltip>
            </View>
            <FormHelperText style={style.helperText}>
              Ihre ausgewählte Hauptkultur wird automatisch vorausgewählt. Dies spart Zeit bei der täglichen Eingabe Ihrer Tätigkeiten.
            </FormHelperText>

            <RadioGroup
              value={favoriteSettings.selectedCulture}
              onChange={(e) => handleCultureChange(e.target.value)}
            >
              {cultures.map((culture) => (
                <FormControlLabel
                  key={culture.uid}
                  value={culture.uid}
                  control={<Radio />}
                  label={culture.name}
                />
              ))}
            </RadioGroup>
          </CardContent>
        </Card>

        <Button
          id="btn_change_data"
          onClick={handleClickChangeData}
          variant="contained"
          fullWidth
          style={style.blueButtons}
          startIcon={<SaveIcon />}
        >
          Speichern
        </Button>

        <Button
          onClick={handleLogoutClick}
          variant="contained"
          fullWidth
          style={style.redButtons}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </ScrollView>
      <View>
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarChangeData}
          TransitionComponent={OurSlideTransition}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon sx={{ mr: '10px' }} />
              <span>Daten wurden gepeichert</span>
            </div>
          }
          key="snackbar-change-data"
        />
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarError}
          TransitionComponent={OurSlideTransition}
          key="snackbar-error">
          <OurAlert severity="error" sx={{ width: '100%' }}>
            Bitte überprüfen Sie Ihre Eingabe!
          </OurAlert>
        </Snackbar>
      </View>
    </View>
  );
}
