import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MPAppBar from '../components/Layout/MPAppBar';
import MPBottomNavigationBar from '../components/Layout/MPBottomNavigationBar';
import SCREEN from '../utils/constants/SCREEN';
import ConsultingSessionList from './ConsultingSessionList';
import Home from './Home';
import Profile from './Profile';

const Tab = createBottomTabNavigator();

const InnerApp = ({ route, navigation }) => {
  //const { memberName } = useGlobalContext();
  const screenOptions = {
    title: '',
  };

  return (
    <Tab.Navigator
      tabBar={(props) => <MPBottomNavigationBar {...props} />}
      initialRouteName={SCREEN.HOME}>
      {/*children components are screen components, tabbed navigation*/}
      <Tab.Screen
        name={SCREEN.HOME}
        component={Home}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} />,
          headerStyle: { height: 200, backgroundColor: 'black' },
          tabBarLabel: 'Home',
          title: 'Home',
        }}
      />
      <Tab.Screen
        name={SCREEN.HOURS}
        component={ConsultingSessionList}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} memberName />,
          tabBarLabel: 'Beratungsstunden',
          title: 'Stundenübersicht',
        }}
      />
      <Tab.Screen
        name={SCREEN.PROFILE}
        component={Profile}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} />,
          tabBarLabel: 'Profil',
          title: 'Nutzerdaten ändern',
        }}
      />
    </Tab.Navigator>
  );
};

export default InnerApp;
