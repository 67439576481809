import MPRestService from './MPRestService';

class AuthService extends MPRestService {
  endpoint;
  CREDENTIALS_KEY = 'credentials';

  constructor(authHeader = "") {
    super(authHeader);
    this.endpoint = '/auth';
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   * @returns {Promise<{isAuthorized: any, uid: any, email: any, password: any}>} authData
   */
  async login(email, password) {
    const data = {
      email,
      password,
    };

    try {
      const response = await this._post(this.endpoint, data);
      await this.saveCredentials({ email, password });
      return response;
    } catch (e) {
      throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
    }
  }

  async saveCredentials(credentials) {
    return localStorage.setItem(this.CREDENTIALS_KEY, JSON.stringify(credentials));
  }

  async getCredentials() {
    const stored = await localStorage.getItem(this.CREDENTIALS_KEY);
    return stored ? JSON.parse(stored) : null;
  }

  async clearCredentials() {
    return localStorage.removeItem(this.CREDENTIALS_KEY);
  }
}

export default AuthService;
