import * as React from 'react';

export const navigationRef = React.createRef();

/**
 * @param {any} name
 * @param {any} params
 */
export function navigateToScreen(name, params) {
  if (navigationRef.current) {
    navigationRef.current.navigate(name, params);
  }
}

/**
 * The reset method lets us replace the navigator state with a new state
 * https://reactnavigation.org/docs/navigation-object/#reset
 * @param {any} name
 * @param {any} params
 */
export function resetToScreen(name, params) {
  if (navigationRef.current) {
    navigationRef.current.reset({
      index: 0,
      routes: [{ name, params }],
    });
  }
} 