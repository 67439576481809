import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, TextField, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import EsteburgLogo from '../components/Common/EsteburgLogo';
import HeaderImage from '../components/Common/HeaderImage';
import { useGlobalContext } from '../contexts/globalContext';
import AuthService from '../services/AuthService';
import SCREEN from '../utils/constants/SCREEN';
import H from '../utils/helper';
import { navigateToScreen } from '../utils/navigation';



export default function Login() {
  const theme = useTheme();
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      // @ts-ignore
      minHeight: '100vh',
    },
    logo: {
      height: 60,
      backgroundColor: '#e5e5e5',
      justifyContent: 'center',
      paddingLeft: 16,
    },
    headerImage: {
      width: '100%',
      marginBottom: 25,
    },
    containerWrapper: {
      alignItems: 'center',
      width: '100%',
      paddingHorizontal: '5%',
    },
    wrapper: {
      minWidth: 350,
      height: 'auto',
      maxWidth: 400,
      // @ts-ignore
      backgroundColor: theme.palette.white,
      borderRadius: 5,
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingBottom: 20
    },
    login: {
      marginTop: 40,
      marginBottom: 25,
      textAlign: 'center',
      fontSize: 28,
      fontWeight: 'bold',
      // @ts-ignore
      color: theme.palette.green,
    },
    innerWrapper: {
      justifyContent: 'center',
      width: '100%',
    },
    input: {
      backgroundColor: '#eef0f3',
      marginBottom: 15,
      width: 'auto',
    },
    errorMsg: {
      color: '#620d12',
      backgroundColor: '#f0c6c8',
      padding: 15,
      fontWeight: '600',
      marginBottom: 10,
      borderRadius: 10,
      fontSize: 16,
    },
    btnLogin: {
      // @ts-ignore
      backgroundColor: theme.palette.green,
      height: 50,
      marginBottom: 10,
      textTransform: 'none',
    },
  });

  const { setActiveTab, setCredentials } = useGlobalContext();
  const [accessGranted, setAccessGranted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [formProps, setFormProps] = useState({ email: '', password: '' });

  const handleFormChange = (event) => {
    setFormProps((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleLoginClick = async (event) => {
    event.preventDefault();
    //Validierung der Anmeldeinformationen
    if (!formProps.email.trim() || !formProps.password.trim()) {
      setErrorMsg('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
      setAccessGranted(false);
    }

    //Login
    const Auth = new AuthService(H.getEncodedCredentials(formProps.email, formProps.password));
    try {
      const authData = await Auth.login(formProps.email, formProps.password);
      if (authData.isAuthorized) {
        setAccessGranted(true);
        await saveCredentials(authData);
        navigateToScreen(SCREEN.INNER_APP, {});
        setActiveTab(0);
      } else {
        throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error.message);
      setAccessGranted(false);
    }
  };

  const saveCredentials = async (authData) => {
    const credentials = {
      uid: authData.uid,
      email: formProps.email,
      password: formProps.password,
      timestamp: Date.now(),
    };

    // Save to context
    setCredentials(credentials);

    // Save to localStorage via AuthService
    try {
      const Auth = new AuthService();
      await Auth.saveCredentials(credentials);
    } catch (error) {
      console.error('Error saving credentials:', error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.logo}>
        <EsteburgLogo width={90} height={50} />
      </View>
      <View style={styles.headerImage}>
        <HeaderImage />
      </View>
      <View style={styles.containerWrapper}>
        <View style={styles.wrapper}>
          <Text style={styles.login}>Anmelden</Text>
          <View style={styles.innerWrapper}>
            {!accessGranted ? (
              <Text
                style={errorMsg !== null && !accessGranted ? styles.errorMsg : { display: 'none' }}>
                {errorMsg}
              </Text>
            ) : null}

            <TextField
              id="email-input"
              name="email"
              label="E-Mail Adresse"
              // @ts-ignore
              style={styles.input}
              value={formProps.email}
              onChange={handleFormChange}
              type="email"
              error={errorMsg !== null && !accessGranted}
            />
            <FormControl variant="outlined"
              // @ts-ignore
              style={styles.input}>
              <InputLabel htmlFor="outlined-adornment-password">Passwort</InputLabel>
              <OutlinedInput
                value={formProps.password}
                name="password"
                onChange={handleFormChange}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                error={errorMsg !== null && !accessGranted}
                label="Passwort"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      id="toggle-password-visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button onClick={handleLoginClick}
              // @ts-ignore
              style={styles.btnLogin} variant="contained">
              Anmelden
            </Button>
          </View>

        </View>
      </View>
    </View>
  );
}
