import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { StyleSheet } from 'react-native';
import ConsultingActivityService from '../../services/ConsultingActivityService';

export default function ConsultingActivitiesDeleteModal({ row, open, onClose, activitiesReload }) {

  const [errorDialogStates, setErrorDialogStates] = useState({
    open: false,
    message: ''
  });


  const styles = StyleSheet.create({
    btngroup: {
      justifyContent: 'center',
      paddingBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
    }
  });

  const handleDelete = async () => {
    try {
      await ConsultingActivityService.deleteConsultingActivity(row.uid);
      activitiesReload();
      onClose();
    } catch (error) {
      console.error(error);
      setErrorDialogStates({ open: true, message: error?.response?.data ?? 'Konnte nicht gelöscht werden' });
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Datensatz löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie <strong>{row.name}</strong> wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.btngroup}>
          <Button variant="contained" color="primary" onClick={onClose} startIcon={<CancelIcon />}>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete} startIcon={<DeleteIcon />}>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorDialogStates.open}
        onClose={() => setErrorDialogStates({ open: false, message: '' })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorDialogStates.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.btngroup}>
          <Button variant="contained" color="primary" onClick={() => setErrorDialogStates({ open: false, message: '' })} startIcon={<CancelIcon />}>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

}

