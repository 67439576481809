export default class UserSettingService {
  static STORAGE_KEY = 'favoriteSettings';

  static getDefaultSettings() {
    return {
      categories: [],
      selectedCulture: ''
    };
  }

  static getSettings() {
    const saved = localStorage.getItem(this.STORAGE_KEY);
    return saved ? JSON.parse(saved) : this.getDefaultSettings();
  }

  static saveSettings(settings) {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(settings));
  }
} 